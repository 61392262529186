/* Add your customizations of the theme here */
//.p-menu {
//  .p-menu-list {
//    .p-menuitem.active {
//      .p-menuitem-link {
//        .p-menuitem-text {
//          color: $primaryColor !important;
//        }
//      }
//    }
//  }
//}
//
//.p-menu .active .p-menuitem-link .p-menuitem-text {
//  color: $primaryColor !important;
//}

.p-divider-solid {
  background-color:  rgba(255, 255, 255, 0.2) !important;
  height: 1px !important;
}
