/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/cropperjs/src/css/cropper.scss";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "../node_modules/swiper/swiper-bundle.min.css";
@import "../node_modules/plyr/dist/plyr.css";
@import "assets/demo/flags/flags.css";
@import 'assets/sass/overrides/layout_styles';
@import 'assets/sass/overrides/theme_styles';
@import 'swipe-bottom-sheet/style.scss';

body {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 300 !important;
  }
}

.app-logo {
  height: 9rem;
}

@media screen and (max-width: $sm) {
  $layoutLeftWidth: 16rem;
  .layout-topbar .layout-topbar-left .layout-topbar-logo {
    width: $layoutLeftWidth;
  }

  .layout-topbar .layout-topbar-left .layout-menu-button {
    left: 14.75rem;
  }
  .menu-wrapper {
    width: $layoutLeftWidth;
  }

  .p-sidebar-sm {
    width: calc(#{$layoutLeftWidth} + 1rem) !important;
  }

  h2.thumbnail-title {
    font-size: 1.4em;
  }
}


@media screen and (max-width: $md) {
  .grid {
    .title-wrapper {
      background: unset !important;
      position: unset !important;
      bottom: unset !important;
      align-items: flex-start !important;
      height: 60px !important;
      padding: 10px !important;
      h2.tile-title {
        color: inherit;
        display: block;
        //@extend .title-wrapper;
        font-size: 0.9em !important;
        font-weight: 200;
      }
    }
    .garage-tile {
      .p-card-footer, .p-card-body {
        display: none;
      }
    }
  }
  h2.thumbnail-title {
    font-size: 1.6em;
  }
}

.cropper-component {
  height: 100% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  background: #121212;
  img {
    //width: 100%;
  }
}

.cropper-modal {
  background-color: #121212 !important;
  opacity: 1 !important;
}

.layout-topbar-dark .layout-topbar .layout-topbar-left .layout-menu-button {
  background-color: #4285f4;
}

.layout-topbar-dark .layout-topbar .layout-topbar-left .layout-menu-button:hover {
  background-color: #499cfa;
  transition: background-color 0.2s;
}

.view {
  position: relative;
  overflow: hidden;
  cursor: default;
}

.overlay .mask:hover {
  opacity: 1;
}

.overlay .mask {
  opacity: 0;
  transition: all .4s ease-in-out;
}

.app-grid-fluid {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
  margin-top: -1rem !important;
}

//.layout-topbar, .layout-topbar-left {
//  height: 5rem;
//}

//.menu-wrapper {
//  width: 18rem;
//  transform: translate3d(-18rem, 0px, 0px);
//}



.layout-menu-dark {
  .layout-search-panel {
    background: black;
  }
  .mat-tab-nav-bar, .mat-mdc-tab-header {
    border-bottom: unset;

    .mat-mdc-tab-header-pagination, .mat-mdc-tab-links {
      background-color: #1e1e1e !important;

      .mat-mdc-tab-header-pagination-chevron {
        color: var(--menu-text-color) !important;
        border-color: var(--menu-text-color) !important;
      }
    }
  }



  .mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab .mdc-tab__text-label,
  .mat-mdc-tab-link .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
    color: var(--menu-text-color) !important;
  }

  .mat-mdc-tab-header-pagination-before, .mat-mdc-tab-header-pagination-after {
    box-shadow: 0px 2px 4px -1px rgba(255, 255, 255, 0.2), 0px 4px 5px 0px rgba(255, 255, 255, 0.14), 0px 1px 10px 0px rgba(255, 255, 255, 0.12);
  }

  mat-tab-header, .mat-tab-list {
    width: 100%;
    background-color: #1e1e1e !important;
    .mat-tab-label, .mat-tab-header-pagination-chevron, .mat-tab-link {
      color: white !important;
      border-color: white !important;
    }
    .mat-elevation-z4 {
      box-shadow: 0px 2px 4px -1px rgba(255, 255, 255, 0.2), 0px 4px 5px 0px rgba(255, 255, 255, 0.14), 0px 1px 10px 0px rgba(255, 255, 255, 0.12);
    }
  }

  .mat-tab-list {
    width: unset;
  }

}

.view-change-button {
  position: absolute;
  left: 10px;
  bottom: 2px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar,
.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #4285f4;
  --mat-tab-header-active-label-text-color: #4285f4;
  --mat-tab-header-active-ripple-color: rgba(255, 255, 255, 0.12);
  --mat-tab-header-inactive-ripple-color: rgba(255, 255, 255, 0.12);
  --mat-tab-header-active-focus-label-text-color: #4285f4;
  --mat-tab-header-active-hover-label-text-color: #4285f4;
  --mat-tab-header-active-focus-indicator-color: #4285f4;
  --mat-tab-header-active-hover-indicator-color: #4285f4;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
  color: #4285f4 !important;
}

mat-ink-bar {
  background-color: #4285f4 !important;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline, .mat-mdc-tab-link .mdc-tab-indicator__content--underline {
  border-color: #4285f4 !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before, .mat-mdc-tab.mdc-tab--active .mat-ripple-element,
.mat-mdc-tab-link.mdc-tab--active .mdc-tab__ripple::before, .mat-mdc-tab-link.mdc-tab--active .mat-ripple-element,
.mat-mdc-tab .mdc-tab__ripple::before,.mat-mdc-tab-link .mdc-tab__ripple::before {
  background-color: #4285f4 !important;
}

div[class^="col-"] {
  position: relative;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 1.2em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.outer {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #4285F4;
}

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(66, 133, 244, 0.32);
}

.p-button-outlined {
  border-width: 2px !important;
  //color: #4285f4!important;
}
a.privacy-no-auth {
  color: #4285f4;
}

app-auth, app-no-auth-layout{
  .auth-box, .no-auth-box {
    $color: #cbcbcb;
    $blue: #4285F4;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    //min-width: 400px;
    word-wrap: break-word;
    background: rgba(30, 30, 30, .5);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-clip: border-box;
    border-radius: 0.25rem;
    padding:0 40px 40px 40px;
    color: $color;
    h1 {
      color: $blue;
      margin: 30px 0;
    }
    small {display:block}
    a:hover {color: $blue}
    .mat-checkbox{
      position: relative;
      left: 47px;
      bottom: 10px
    }
    .p-inputtext {
      background: transparent !important;
    }
    .p-float-label input.p-filled ~ label {
      background: #1e1e1e;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    }
    .p-checkbox-box {
      background: unset;
    }
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      border-color: $blue;
      background: $blue;
      color: $blue;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: $blue;
      background: $blue;
    }
    .fa-thumbs-o-up {
      top: 20px;
      position: relative;
    }
    .mat-checkbox-frame {
      border-color: $color;
    }
    .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
      background-color: $blue;
    }
    .md-form {
      margin-top: 1.0rem;
      $margin-bottom: 0;
      margin-bottom: $margin-bottom;
      label {
        color: $color;
      }

      .form-control {
        color: white;
      }
      button {
        min-width: 130px;
      }
    }

    input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
      background-color: rgba(250, 255, 189, .0) !important;
      background-image: none !important;
      color: rgb(0, 0, 0) !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: color 9999s ease-out, background-color 9999s ease-out !important;
      transition-delay: 9999s !important;
    }

    .app-snackbar-error {
      margin-left: 15px;
    }
  }

  .p-button-outlined {
    border-width: 1px !important;
    //color: #4285f4!important;
  }
  .p-button-primary {
    border: 1px solid #4285f4!important;
    color: #4285f4!important;
  }

  .p-button-danger {
    border: 1px solid #ff3547!important;
    color: #ff3547!important;
  }
}

.p-field {
  margin-bottom: 0.5rem
}

.app-form-error {
  position: relative;
  top: 5px;
  font-size: 11px;
  color: red;
  padding-bottom: 0;
  //padding-top: 0.5rem;
  p {
    margin-bottom: 0;
  }
}

.cdk-overlay-container {
  z-index: 1000000 !important;
}

.mat-form-field {
  line-height: 2.200;
}

div.stretchy-wrapper {
  width: 100%;
  padding-bottom: 38.75%; /* 16:9 */
  position: relative;
  background: black;
}

div.stretchy-wrapper > img {
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  color: white;
  font-size: 24px;
  text-align: center;
  height: 100%;
  width:100%
}

.shadow-off {
  box-shadow: none !important;
}
.project {
  .mat-expansion-panel-header {
    font-size: 17px;
    h1 {
      font-size: 14px;
    }
  }
  .mat-list-item {height: auto !important; font-size:14px !important;}
}

.mat-tab-body-content {
  height: 100%;
  overflow: visible;
}

.setting-icon {
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(https://mdbootstrap.com/img/svg/hamburger8.svg?color=E3005C);
}

.blurred-area {
  background-color: rgba(55, 55, 55, 0.71) !important;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  margin: 0 auto;
  position: fixed;
}

.zIndexed {
  z-index: 11;
}

.absoluteStick {
  z-index: 999;
  position: fixed !important;
  top: 90px;
  padding-top: 25px;
  background-color: white !important;
}

.social {
  font-size: 1.3em;
}

.navbar-collapse {
  height: 3.125rem;
}

.p-overlay-badge .p-badge {
  font-size: 0.7rem;
  font-weight: 700;
  min-width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
  //background: lightgoldenrodyellow;
  color: #212121;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.project-slidemenu {
  width: 100% !important;
  .p-slidemenu-root-list{
    width: unset !important;
  }
  .p-slidemenu-wrapper {
    height: 100% !important;
    width: 100% !important;
  }
  .p-slidemenu-content {
  }
  .p-slidemenu-rootlist, .p-submenu-list, .p-active-submenu {
     width: 100% !important;
  }
}

.project-tags {
  padding: 2px 2em;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  width: 100%;
  opacity: 0.8;
  gap: 4px;
  flex-wrap: wrap;
  .p-tag {
    color: white;
    font-size: 1em;
    padding:3px 10px;
    background: rgba(0,0,0, 0.5);
    a {
      color: white;
      &:first-letter {
        font-size: 1.2em;
        color: #FFB74D;
      }
    }
  }
}

.timeline-tile {
  .p-menu-overlay {
    right: 0;
    top: 55px;
  }
  .p-card {
    .p-card-content {
      padding: 0;
    }
  }
}

.p-sidebar-content {
  overflow-y: unset !important;
}

.lang-button-absolute {
  position: absolute; top: 10px; right: 10px
}

.text-danger {
  color: red !important;
}

@keyframes fadeinleft {
  0%   {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform .12s cubic-bezier(0, 0, 0.2, 1), opacity .12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1 !important;
    transform: translateX(0%);
  }
}

.layout-topbar, .menu-wrapper {
  z-index: 1000;
}

.loader-overlay {
  top: 0;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  background: rgba(64, 64, 64, 0.6);
  z-index: 6100;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #4285f4;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

button,
a{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.layout-wrapper.layout-locked {
  overflow: hidden;
  display: inline-block;
}

.p-overflow-hidden {
  .mat-tab-header {
    z-index: 1;
  }
  .background-toolbox {
    z-index: 0 !important;
  }
}

.custom-camera-transparent-background {
  background-color: transparent !important;
}

.header-wrapper {
  position: relative;
  background: linear-gradient(0deg, rgba(36, 36, 36, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
}
.mat-tab-body {
  z-index:-1;
}
.mat-tab-body.mat-tab-body-active {
  z-index: auto !important;
}

.p-menu .active .p-menuitem-link .p-menuitem-text, .p-menu .active .p-menuitem-link .p-menuitem-icon {
  color: rgba(255, 255, 255);
}

.center-positioned {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
}
